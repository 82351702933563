// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/theme/layout"
import Image from "../components/atoms/image"
import SEO from "../components/organisms/seo"
import styled from "styled-components"

const AboutPage: React.FC<PageProps> = ({ data }) => {
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes("philosophy.jpg")
  })
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土 - About"
        description="これまで私たちはジャグリングの舞台公演『秘密基地』をおこなってきました。『秘密基地』は日本各地からジャグラーを集め、それぞれ10分程度の作品を上演するオムニバス公演です。テーマとして「ジャグリングの新たな魅力を開拓する」を掲げ、舞台でのジャグリングの可能性を広げてきました。"
        picUrl={domain + image.node.childImageSharp.sizes.src}
      />
      <Section>
        <TitleMain>About</TitleMain>
        <MainImg filename="about.jpg" />
        <TitleDesc>
          これまで私たちはジャグリングの舞台公演『秘密基地』をおこなってきました。
          <br />
          『秘密基地』は日本各地からジャグラーを集め、それぞれ10分程度の作品を上演するオムニバス公演です。
          <br />
          テーマとして「ジャグリングの新たな魅力を開拓する」を掲げ、舞台でのジャグリングの可能性を広げてきました。
          <br />
          <br />
          しかし、新型感染症の流行以降、舞台での活動は継続することが難しくなりました。
          <br />
          このような状況は、厳しいものではありますが、自分たちの活動の基盤を考え直すための好機でもあります。
          <br />
          ジャグリングを行う場は舞台である必要があるのか、ジャグリングをするのはいわゆる「ジャグラー」である必要があるのか、そもそもジャグリングとは何であるのか。
          <br />
          ジャグリングをどこで、誰が、どのようにするのか、を考え直し、改めて実践するために、私たちは「ワークショップ」を行うことにしました。
          <br />
          <br />
          ワークショップは、座学と演習で構成されます。
          <br />
          座学では、日本・世界で行われてきた様々なジャグリングを振り返ることで、これまで行われてきたことが何であったのかを整理します。
          <br />
          演習では、座学での振り返りを活かしつつ、自分だけの「道具とのつながり方」を模索することで、新たな「ジャグリング」の実践を目指します。
          <br />
          この二つを通じて、「身体と道具をつなぐもの」の可能性を探ります。
          <br />
          <br />
          「身体と道具をつなぐもの」つまりジャグリングは、今までになかった新しい思考＝実践のスタイルであり、例えば日常生活の見方を変えてくれるものであると私たちは考えています。
          <br />
          私たちのワークショップは誰でもが参加・実践できるものを目指しています。
          <br />
        </TitleDesc>
      </Section>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
const Section = styled.section`
  margin: 40px 0 60px;
  padding: 0 14px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 80px 0 60px;
  }
`

const MainImg = styled(Image)`
  maxwidth: 900;
  height: 275;
  margin: "0 auto";

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    height: 550;
  }
`

const TitleMain = styled.h1`
  margin: 8px auto 40px;
  font-size: 2.25rem;
  text-align: center;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const TitleDesc = styled.p`
  margin: 40px 0 20px;
`
